<template>
  <div class="flex flex-col min-h-screen justify-center">
    <div
      class="
        bg-white
        container
        w-full
        sm:w-2/3
        mx-auto
        rounded-md
        z-10
        py-4
        px-6
        items-start
      "
    >
      <login-component></login-component>
    </div>
    <div class="absolute top-0 right-0">
      <img src="@/assets/logo_left_bar.png" width="140" alt="" />
    </div>
    <div class="absolute bottom-0 left-0">
      <img src="@/assets/logo_right_bar.png" width="140" alt="" />
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/views/auth/LoginComponent";
export default {
  name: "Login",
  components: { LoginComponent },
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/auth/login") {
        this.activeTab = 0;
      } else if (to.path === "/auth/register") {
        this.activeTab = 1;
      }
    },
  },
  methods: {
    openLogin() {
      this.activeTab = 0;
      this.$router.push("/auth/login");
    },
    openRegister() {
      this.activeTab = 1;
      this.$router.push("/auth/register");
    },
  },
};
</script>

<style scoped></style>
