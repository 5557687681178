<template>
  <div class="flex flex-col w-full py-6 space-y-8 items-center">
    <div class="flex flex-row justify-center items-center w-full space-x-4">
      <img class="w-10" src="@/assets/logo.png" alt="" />
      <h1 class="font-bold text-4xl">{{ $t("PJTelesoftB2C") }}</h1>
    </div>
    <p class="text-2xl pt-4 font-poppins font-medium">
      {{ $t("welcomeBack") }}
    </p>
    <Form
      @submit="onSubmit"
      v-slot="{ meta }"
      :validation-schema="schema"
      class="flex flex-col w-full md:w-1/2 pt-12 pb-12 space-y-8 items-start"
    >
      <div class="w-full text-left">
        <Field
          type="email"
          name="email"
          v-model="email"
          placeholder="Email"
          class="
            form-input
            px-4
            py-4
            rounded
            bg-input-grey
            border-0
            w-full
            focus:ring-brand
          "
        />
        <ErrorMessage class="text-red-500" name="email" />
      </div>
      <div class="w-full text-left">
        <Field
          type="password"
          name="password"
          v-model="password"
          placeholder="Password"
          class="
            form-input
            px-4
            py-4
            rounded
            bg-input-grey
            border-0
            w-full
            focus:ring-brand
          "
        />
        <ErrorMessage class="text-red-500" name="password" />
      </div>

      <button
        type="submit"
        class="base-gradient w-full rounded px-4 py-4 text-white font-bold"
        :class="{
          'opacity-50 cursor-not-allowed': !meta.valid,
          'opacity-100 cursor-pointer': meta.valid,
        }"
      >
        {{ $t("login") }}
      </button>
    </Form>
  </div>
</template>

<script>
import {
  useForm,
  useIsFormValid,
  Field,
  Form,
  ErrorMessage,
} from "vee-validate";
import * as yup from "yup";
import { default as toast } from "@/utils/toastUtils";

export default {
  name: "LoginComponent",
  setup() {
    // Define a validation schema
    const schema = yup.object({
      email: yup.string().required().min(2),
      password: yup.string().required().min(8),
    });

    const { meta, errors } = useForm({
      validationSchema: schema,
    });

    return {
      schema,
      meta,
    };
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    onSubmit(values) {
      let data = {
        username: values.email,
        password: values.password,
      };
      this.$store
        .dispatch("performLogin", data)
        .then((data) => {
          toast.success(this.$t("loginSuccessful"));
          this.$router.push("/");
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  },
};
</script>

<style scoped></style>
